import type {RouteRecordRaw} from "vue-router";
import {useCurrentTenantStore} from "@/stores/current-tenant";
import {studiesIndex, studyRoutes} from "@/modules/studies/router";
import assetsRoutes from "@/modules/assets/router";
import auditRoutes from "@/modules/audit/router";
import clientsRoutes from '@/modules/clients/router';
import { useCurrentUserStore } from '@/stores/current-user'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        beforeEnter: async (to) => {
            try {
                await (useCurrentUserStore().getCurrentUser())
                const {loadCurrentTenant} = useCurrentTenantStore();
                await loadCurrentTenant();
                return true;
            } catch (e) {
                return {name: 'dashboard'}
            }
        },
        meta: {
            auth: true,
            breadcrumb: {key: 'organization.entity'}
        },
        name: 'tenant-context',
        redirect: {name: 'organization.dashboard'},
        children: [
            {
                path: '',
                name: 'tenant.index',
                component: () => import('@/modules/organizations/views/OrganizationView.vue'),
                redirect: {name: 'organization.dashboard'},
                children: [
                    {
                        name: 'organization.dashboard',
                        path: 'dashboard',
                        meta: {
                            breadcrumb: {
                                key: 'organization.dashboard',
                            }
                        },
                        component: () => import('@/modules/organizations/views/OrganizationDashboardView.vue')
                    },
                    {
                        path: 'collaborators',
                        name: 'organization.collaborators',
                        component: () => import('@/modules/organizations/views/OrganizationAdministratorsView.vue')
                    },
                    {
                        path: 'participants',
                        name: 'organization.participants',
                        component: () => import('@/modules/organizations/views/OrganizationParticipantsView.vue')
                    },
                    {
                        name: 'organization.devices',
                        path: 'devices',
                        meta: {
                            breadcrumb: {
                                key: 'device.entity',
                                plural: 2
                            }
                        },
                        component: () => import('@/modules/organizations/views/OrganizationDevicesView.vue')
                    },
                    {
                        path: 'settings',
                        name: 'organization.settings',
                        component: () => import('@/modules/organizations/views/OrganizationSettingsView.vue'),
                        meta: {
                            breadcrumb: {
                                key: 'general.settings'
                            }
                        },
                        redirect: {
                            name: 'organization.settings.collaborators'
                        },
                        children: [
                            {
                                path: 'collaborators',
                                name: 'organization.settings.collaborators',
                                props: true,
                                component: () => import('@/modules/organizations/views/OrganizationSettingsCollaboratorsView.vue')
                            },
                            {
                                path: 'sync',
                                name: 'organization.settings.sync',
                                component: () => import('@/modules/organizations/views/OrganizationSettingsSync.vue'),
                            },
                            {
                                path: 'languages',
                                name: 'organization.settings.languages',
                                props: true,
                                component: () => import('@/modules/organizations/views/OrganizationSettingsLanguagesView.vue'),
                                children: [
                                    {
                                        path: 'create',
                                        name: 'organization.settings.languages.create',
                                        component: () => import('@/modules/organizations/components/LanguageForm.vue')
                                    },
                                    {
                                        path: ':languageId/edit',
                                        name: 'organization.settings.languages.edit',
                                        props: true,
                                        component: () => import('@/modules/organizations/components/LanguageForm.vue')
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'update',
                        name: 'organization.update',
                        component: () => import('@/modules/organizations/views/OrganizationUpdate.vue'),
                        redirect: {name: 'organization.update.general'},
                        children: [
                            {
                                path: 'general',
                                name: 'organization.update.general',
                                component: () => import('@/modules/organizations/views/OrganizationUpdateGeneral.vue')
                            },
                            {
                                path: 'translations',
                                name: 'organization.update.translations',
                                component: () => import('@/modules/organizations/views/OrganizationUpdateTranslations.vue')
                            }
                        ]
                    },
                    ...studiesIndex,
                    ...assetsRoutes,
                    ...auditRoutes,
                    ...clientsRoutes
                ]
            },
            ...studyRoutes,

        ]
    }
];


export default routes;
