import type {RouteRecordRaw} from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: 'clients',
        meta: {
            breadcrumb: {
                key: 'client.entity',
                plural: 2
            }
        },
        children: [
            {
                path: '',
                name: 'clients.index',
                component: () => import('@/modules/clients/views/ClientsIndexView.vue')
            },
            {
                path: ':clientId',
                name: 'clients.show',
                component: () => import('@/modules/clients/views/ClientShowView.vue'),
                props: true,
            },
            {
                path: ':clientId/update',
                name: 'clients.update',
                component: () => import('@/modules/clients/views/ClientUpdateView.vue'),
                props: true,
            }
        ]
    }
]

export default routes;
