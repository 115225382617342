import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import type {User, SigninRedirectArgs, SignoutRedirectArgs} from 'oidc-client-ts';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'
import type {Router} from "vue-router";
import TheOidcRedirectCallback from "@/modules/authentication/components/TheOidcRedirectCallback.vue";
import {CookieStorage} from 'cookie-storage';

const getUrlPath = (url: string) => {
    const link = document.createElement('a')
    link.href = url
    let path = link.pathname
    if (path[0] !== '/') path = '/' + path
    return path;
}

const cookieStorage = new CookieStorage({
    path: '/',
    domain: window.hsp.OIDC_COOKIE_DOMAIN
})

export const useAuthStore = defineStore('auth', () => {

    const manager = new UserManager({
        authority: window.hsp.OIDC_AUTHORITY,
        client_id: window.hsp.OIDC_CLIENT_ID,
        redirect_uri: `${window.location.origin}/oidc-callback`,
        scope: 'openid offline_access',
        post_logout_redirect_uri: `${window.location.origin}/endsession`,
        automaticSilentRenew: true,
        monitorSession: true,
        userStore: new WebStorageStateStore({store: cookieStorage}),
    });
    const user = ref<User | null>(null);

    const isAuthenticated = computed(() => !!user.value && !user.value.expired);
    const accessToken = computed<string>(() => !!user.value && !user.value.expired ? user.value.access_token : '' );
    const profile = computed(() => !!user.value && !user.value.expired ? user.value.profile : {
        iss: '',
        sub: '',
        aud: '',
        exp: 0,
        iat: 0
    });



    const signIn = async (args?: SigninRedirectArgs) => {
        return manager.signinRedirect(args);
    };

    const signOut = async (args?: SignoutRedirectArgs) => {
        manager.stopSilentRenew();
        return await manager.signoutRedirect(args);
    }

    const startSilentRenew = () => {
        manager.startSilentRenew();
    }

    const stopSilentRenew = () => {
        manager.stopSilentRenew();
    }

    const signinRedirectCallback = async () => {
        return await manager.signinRedirectCallback();
    }

    const registerRouting = (router: Router) => {
        router?.beforeEach(async (to, from, next) => {
            if (to.matched.some((record) => record.meta.auth === true)) {

                if (isAuthenticated.value) {
                    next();
                } else {
                    try {
                        await signIn({state: {to}});
                        next();
                    } catch (e) {
                        next({path: '/'});
                    }

                }
            } else {
                next();
            }
        });

        if (manager.settings.redirect_uri) {

            const routePath = `/${getUrlPath(manager.settings.redirect_uri).substring((router?.options.history.base || '/').length)}`;

            router?.addRoute({
                path: routePath,
                component: TheOidcRedirectCallback
            })
        }
    }



    manager.events.addUserLoaded((loadedUser) => {
        user.value = loadedUser;
    });

    manager.events.addUserUnloaded(() => {
        user.value = null;
    });

    manager.events.addAccessTokenExpired(() => {

        if (isAuthenticated.value) {
            startSilentRenew();
        }
    })

    manager.events.addUserSignedOut(() => {
        user.value = null;
    })




    return { user, isAuthenticated, accessToken, profile, registerRouting, signIn, signOut, startSilentRenew, stopSilentRenew, signinRedirectCallback }

})
