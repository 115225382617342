import {defineStore} from "pinia";
import {useStudiesClient} from "@/utils/api.utils";
import {computed, ref, watch} from "vue";
import {LanguageDto, StudyDetailsDto, UpdateStudyCommand} from "@/api/TenantAPIClient";
import {useWorkingCopy} from "@/composables/working-copy";

export const useStudyStore = defineStore('study', () => {

        const studiesClient = useStudiesClient();
        const study = ref<StudyDetailsDto>();
        const {workingCopy, resetWorkingCopy} = useWorkingCopy<StudyDetailsDto>(study);
        const loading = ref<boolean>(false);
        const updating = ref<boolean>(false);


        const defaultLanguage = computed<LanguageDto | undefined>(() => {
            return study.value?.defaultLanguage;
        });

        const languages = computed<LanguageDto[]>({
            get: () => {
                return study.value?.languages ?? [];
            },
            set: (value: LanguageDto[]) => {
                study.value!.languages = value;
            }
        });

        const getStudy = async (studyId: string) => {

            loading.value = true;

            try {

                study.value = await studiesClient.getStudy(studyId);

            } finally {
                loading.value = false;
            }

        }

        const updateStudy = async () => {

            updating.value = true;

            try {

                const command = new UpdateStudyCommand({
                    visibility: workingCopy.value!.visibility,
                    name: workingCopy.value!.name,
                    defaultLanguageId: workingCopy.value!.defaultLanguage.id,
                    description: workingCopy.value!.description,
                    languageIds: workingCopy.value!.languages.map((lang) => lang.id),
                    studyId: workingCopy.value!.id,
                    shortDescription: workingCopy.value!.shortDescription,
                    title: workingCopy.value!.title,
                    assetId: workingCopy.value!.asset?.id
                })

                study.value = await studiesClient.updateStudy(study.value!.id, command);
            } catch (e) {
                console.log(e)
            } finally {
                updating.value = false;
            }
        }



        return {
            study,
            workingCopy,
            loading,
            updating,
            languages,
            defaultLanguage,
            getStudy,
            updateStudy,
            resetWorkingCopy
        }
})
