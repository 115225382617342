import {defineStore} from "pinia";
import {computed, ref} from "vue";
import type {
    IUpdateCurrentUserCommand,
    TenantDto,
} from "@/api/GatewayAPIClient";
import {useCurrentUserClient} from "@/utils/api.utils";
import {UpdateCurrentUserCommand, UserDetailsDto} from "@/api/GatewayAPIClient";
import {UsersClient} from "@/api/TenantAPIClient";
import {useAuthStore} from "@/modules/authentication/stores/auth";

export const useCurrentUserStore = defineStore('current-user', () => {

    const authStore = useAuthStore();
    const currentUsersClient = useCurrentUserClient();
    const user = ref<UserDetailsDto | null>(null);
    const tenants = ref<TenantDto[]>([]);
    const activeTenantId = ref<string>();

    const activeTenant = computed(() => {
        return tenants.value.find(({id}) => id === activeTenantId.value)
    })

    const fullName = computed(() => {

        if (!user.value || !user.value.givenName || !user.value.familyName) {
            return `Anonymous`
        }

        return `${user.value.givenName} ${user.value.familyName}`;
    });
    const initials = computed(() => {

        if (!user.value || !user.value.givenName || !user.value.familyName) {
            return undefined;
        }

        return `${user.value?.givenName?.charAt(0)}${user.value?.familyName?.charAt(0)}`;
    });

    const roles = computed(() => {
        return user.value?.roles ?? [];
    })

    const getCurrentUser = async () => {
        try {
            user.value = await currentUsersClient.getCurrentUser();
            const {items} = await currentUsersClient.getCurrentUserTenants();
            tenants.value = items;
        } catch (e) {
            console.log(e)
        }
    }

    const updateCurrentUser = async (command: IUpdateCurrentUserCommand) => {

        try {
            user.value = await currentUsersClient.updateCurrentUser(new UpdateCurrentUserCommand(command));
        } catch (e) {
            console.log(e)
        }
    }

    const setActiveTenant = (tenantId: string) => {
        activeTenantId.value = tenantId;
    }

    /**
     * TODO: Remove as soon as super administrators are automatically added when creating a new tenant
     */
    const selfEnrollAsTenantAdministrator = async (tenant: TenantDto) => {
        const tenantUsersClient = new UsersClient(`${window.hsp.API_BASE_URL}/tenants/${tenant.slug}`, {
            fetch(url: RequestInfo, init: RequestInit) {

                init.headers = {
                    ...init.headers,
                    Authorization: `Bearer ${authStore.accessToken}`
                }

                return fetch(url, init)
            }
        });
        await tenantUsersClient.addUser(user.value!.id);
        const {items} = await currentUsersClient.getCurrentUserTenants();
        tenants.value = items;
    }


    return {
        user,
        fullName,
        initials,
        roles,
        tenants,
        activeTenant,
        updateCurrentUser,
        getCurrentUser,
        setActiveTenant,
        selfEnrollAsTenantAdministrator
    };

})
