import type {RouteRecordRaw} from "vue-router";

const assetsRoutes: RouteRecordRaw[] = [
    {
        path: 'assets/:folderId?',
        name: 'assets.folder',
        meta: {
            breadcrumb: {
                key: 'asset.entity',
                plural: 2
            }
        },
        props: true,
        component: () => import('@/modules/assets/components/AssetFolder.vue'),
    }
]

export default assetsRoutes;
