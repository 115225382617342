import {ref, type Ref} from "vue";
import {watchDeep} from "@vueuse/core";

interface ICloneable<T=any> {
    clone: () => T
}


export const useWorkingCopy = <T>(of: Ref<ICloneable<T> | undefined>) => {

    const workingCopy = ref<T>();

    const resetWorkingCopy = () => {
        workingCopy.value = of.value?.clone();
    }

    watchDeep(
        () => of.value,
        (value) => {
            workingCopy.value = value?.clone();
        }
    )

    return {
        workingCopy,
        resetWorkingCopy
    }

}
