import {ContentDto, ContentGroupDto, ContentPageDto} from "@/api/TenantAPIClient";

const canHaveNestedContent = (element: ContentDto): element is (ContentPageDto | ContentGroupDto) => {
    return element instanceof ContentPageDto || element instanceof ContentGroupDto;
}

export const getElementById = (elementId: string, elements: ContentDto[]) : ContentDto | null => {


        for(const element of elements) {
            if (element.id === elementId) {
                return element;
            } else if (element instanceof ContentGroupDto || element instanceof ContentPageDto) {
                const el =  getElementById(elementId, element.contents);

                if (el) {
                    return el;
                }
            }
        }

        return null;
}


export const removeElementById = (elementId: string, elements: ContentDto[]) => {

    for(let index = 0; index < elements.length; index++) {

        const currentElement = elements.at(index)!;

        if (currentElement.id === elementId) {
            elements.splice(index, 1);
            break;
        } else if (canHaveNestedContent(currentElement)) {
            removeElementById(elementId, currentElement.contents);
        }

    }

}


export const replaceElement = (updatedElement: ContentDto, elements: ContentDto[])=> {

    for (let index = 0; index < elements.length; index++) {

        const currentElement = elements.at(index)!;

        if (currentElement.id === updatedElement.id) {
            elements.splice(index, 1, updatedElement);
            break;
        } else if (canHaveNestedContent(currentElement)) {
            replaceElement(updatedElement, currentElement.contents);
        }
    }
}

export const insertAfterElement = (newElement: ContentDto, elementId: string, elements: ContentDto[]) => {

    for( let index = 0; index < elements.length; index++) {

        const currentElement = elements.at(index)!;

        if (currentElement.id === elementId) {
            elements.splice(index + 1, 0, newElement);
            break;
        } else if (canHaveNestedContent(currentElement)) {
            insertAfterElement(newElement, elementId, currentElement.contents);
        }

    }

}

export const flattenElements = (elements: ContentDto[]) : ContentDto[] => {
    let result: ContentDto[] = [];

    for(const element of elements) {

        result.push(element);

        if (element instanceof ContentGroupDto || element instanceof ContentPageDto) {
            result = result.concat(flattenElements(element.contents));
        }
    }

    return result;
}
