import type {RouteRecordRaw} from "vue-router";
import {useStudyStore} from "@/modules/studies/stores/study";
import questionnaireRoutes from "@/modules/questionnaires/router";
import schedulesRoutes from "@/modules/schedules/router";
import {pageRoutes} from "@/modules/app/router";
import {useContextObjectStore} from "@/modules/studies/stores/context-object";

export const studiesIndex: RouteRecordRaw[] = [
    {
        path: 'studies',
        name: 'studies.index',
        meta: {
            breadcrumb: {
                key: 'study.entity',
                plural: 2
            }
        },
        component: () => import('@/modules/studies/views/StudiesIndex.vue'),
        children: [
            {
                path: 'create',
                name: 'studies.create',
                component: () => import('@/modules/studies/views/StudiesCreate.vue')
            }
        ],
    }
];

export const studyRoutes: RouteRecordRaw[] = [
    {
        // Has to be optional atm because of https://github.com/vuetifyjs/vuetify/issues/17176
        path: 'studies/:studyId',
        name: 'study.context',
        beforeEnter: async (to) => {
            const studyStore = useStudyStore();
            const {studyId} = to.params;
            await studyStore.getStudy(studyId as string);
        },
        meta: {
            breadcrumb: () => {
                const {study} = useStudyStore();
                return study!.name;
            }
        },
        component: () => import('@/modules/studies/views/StudiesShow.vue'),
        redirect: {name: 'study.dashboard'},
        children: [
            {
                path: 'dashboard',
                name: 'study.dashboard',
                component: () => import('@/modules/studies/views/StudyDashboardView.vue'),
            },
            ...schedulesRoutes,
            ...pageRoutes,
            {
                path: 'participants',
                children: [
                    {
                        path: '',
                        name: 'study.participants',
                        component: () => import('@/modules/studies/views/StudyParticipantsView.vue'),
                    },
                    {
                        path: ':participantId',
                        name: 'study.participant',
                        component: () => import('@/modules/studies/views/StudyParticipantView.vue'),
                        props(to) {
                            const {studyId, participantId} = to.params;
                            return {
                                studyId,
                                participantId,
                            }
                        },
                    }
                ]
            },
            {
                path: 'configuration',
                name: 'study.configuration',
                component: () => import('@/modules/studies/views/StudyConfigurationView.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'study.context.create',
                        component: () => import('@/modules/studies/views/ContextObjectsCreate.vue')
                    }
                ]
            },
            {
                path: 'configuration/:contextObjectId?',
                name: 'contextObject.details',
                beforeEnter: async (to) => {
                    const {getContextObject} = useContextObjectStore();
                    const {contextObjectId} = to.params;
                    await getContextObject(contextObjectId as string);
                },
                redirect: {name: 'contextObject.show'},
                children: [
                    {
                        path: '',
                        name: 'contextObject.show',
                        component: () => import('@/modules/studies/views/ContextObjectsShow.vue')
                    },
                    {
                        path: 'update',
                        name: 'contextObject.update',
                        component: () => import('@/modules/studies/views/ContextObjectUpdate.vue'),
                        redirect: {
                            name: 'contextObject.update.contents'
                        },
                        children: [
                            {
                                path: 'contents',
                                name: 'contextObject.update.contents',
                                component: () => import('@/modules/studies/views/ContextObjectDesignerView.vue'),
                            },
                            {
                                path: 'translation',
                                name: 'contextObject.update.translations',
                                component: () => import('@/modules/studies/views/ContextObjectTranslationView.vue'),
                            },
                            {
                                path: 'settings',
                                name: 'contextObject.update.settings',
                                component: () => import('@/modules/studies/views/ContextObjectSettingsView.vue'),
                            }
                        ]
                    }
                ]
            },
            {
                path: 'settings',
                name: 'study.settings',
                component: () => import('@/modules/studies/views/StudyUpdateView.vue'),
                redirect: {name: 'study.settings.update.general'},
                children: [
                    {
                        path: 'general',
                        name: 'study.settings.update.general',
                        component: () => import('@/modules/studies/views/StudyUpdateGeneralView.vue')
                    },
                    {
                        path: 'translation',
                        name: 'study.settings.update.translation',
                        component: () => import('@/modules/studies/views/StudyUpdateTranslationView.vue')
                    }
                ]
            },
            {
                path: 'collaborators',
                name: 'study.collaborators',
                component: () => import('@/modules/studies/views/StudyCollaboratorsView.vue')
            },
            ...questionnaireRoutes,
            {
                path: 'playground',
                component: () => import('@/views/Playground.vue')
            }
        ],
    }
]
