import type {RouteRecordRaw} from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: 'questionnaires',
        children: [
            {
                path: '',
                name: 'questionnaires.index',
                component: () => import('@/modules/questionnaires/views/QuestionnairesIndex.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'questionnaires.create',
                        component: () => import('@/modules/questionnaires/views/QuestionnairesCreate.vue')
                    },
                ]
            },
            {
                path: ':questionnaireId',
                component: () => import('@/modules/questionnaires/views/QuestionnaireIndex.vue'),
                props: true,
                children: [
                    {
                      path: '',
                      name: 'questionnaires.show',
                      component: () => import('@/modules/questionnaires/views/QuestionnairesShow.vue')
                    },
                    {
                        path: 'edit',
                        name: 'questionnaires.update',
                        component: () => import('@/modules/questionnaires/views/QuestionnairesUpdate.vue'),
                        redirect: {name: 'questionnaire.designer'},
                        children: [
                            {
                                path: 'designer',
                                name: 'questionnaire.designer',
                                component: () => import('@/modules/questionnaires/views/QuestionnaireDesigner.vue'),
                            },
                            {
                                path: 'settings',
                                name: 'questionnaire.settings',
                                component: () => import('@/modules/questionnaires/views/QuestionnaireSettings.vue')
                            },
                            {
                                path: 'internationalization',
                                name: 'questionnaire.internationalization',
                                component: () => import('@/modules/questionnaires/views/QuestionnaireInternationalization.vue')
                            }
                        ]
                    }
                ],

            }
        ]
    },
    {
        path: 'questionnaire-instances',
        children: [
            {
                path: '',
                name: 'questionnaireInstances.index',
                component: () => import('@/modules/questionnaires/views/QuestionnaireInstancesIndex.vue'),
            },
            {
                path: ':instanceId',
                name: 'questionnaireInstances.show',
                component: () => import('@/modules/questionnaires/views/QuestionnaireInstanceShow.vue'),
                props: true,
            }
        ]
    }
]

export default routes;
