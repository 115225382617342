import {useRouter} from "vue-router";
import {computed, ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export const useBreadcrumbs = () => {
    const {currentRoute, resolve} = useRouter();
    const {t} = useI18n();

    const breadcrumbs = ref<any[]>([]);

    watch(
        () => currentRoute.value,
        (value) => {

            breadcrumbs.value = [
              {
                title: t('Home'),
                href: window.hsp.GATEWAY_BACKEND_BASE_URL
              },
              ...value.matched
                .filter((matched) => matched.meta.breadcrumb)
                .map((matched) => {
                  const {meta: {breadcrumb}} = matched;

                  const to = resolve(matched, currentRoute.value).fullPath;

                  let title: string;

                  if (typeof breadcrumb === 'function') {
                    title = breadcrumb();
                  } else if (typeof breadcrumb === 'object') {
                    const {key, plural} = breadcrumb as {key: string, plural?: number};
                    title = t(key, plural ?? 1);
                  } else {
                    title = breadcrumb! as string;
                  }

                  return {
                    title,
                    to
                  }
                })
            ]
        },
        {immediate: true}
    )


    return {breadcrumbs}
}
