import {defineStore} from "pinia";
import {useContextObjectsClient} from "@/utils/api.utils";
import {computed, ref} from "vue";
import type {ContextObjectDetailsDto} from "@/api/TenantAPIClient";
import {ContentDto, UpdateContextObjectCommand} from "@/api/TenantAPIClient";
import {getElementById, replaceElement} from "@/modules/questionnaires/utils/questionnaire-operations";
import {useStudyStore} from "@/modules/studies/stores/study";
import {useChanges} from "@/composables/save";
import {useWorkingCopy} from "@/composables/working-copy";

export const useContextObjectStore = defineStore('context-object', () => {

    const studyStore = useStudyStore();
    const contextObjectsClient = useContextObjectsClient();

    const loading = ref(false);
    const publishing = ref<boolean>(false);
    const unpublishing = ref<boolean>(false);
    const saving = ref(false);

    const contextObject = ref<ContextObjectDetailsDto>();
    const {workingCopy, resetWorkingCopy} = useWorkingCopy(contextObject);

    const defaultLanguage = computed(() => {
        return contextObject.value?.defaultLanguage;
    });

    const languages = computed(() => {
        return contextObject.value?.languages;
    })

    const isPublished = computed(() => {
        return contextObject.value?.published !== undefined;
    })

    const getContextObject = async (id: string) => {

        loading.value = true;

        try {
            contextObject.value = await contextObjectsClient.getContextObject(id);
        } catch (e) {
            console.log(e);
        } finally {
            loading.value = false;
        }
    }

    const getElement = (elementId: string) => {
        return getElementById(elementId, workingCopy.value!.contents as ContentDto[]);
    }

    const updateElement = (element: ContentDto) => {
        replaceElement(element, workingCopy.value!.contents as ContentDto[]);
    }

    const publishContextObject = async () => {

        if (!contextObject.value) {
            return;
        }

        try {

            publishing.value = true;
            await contextObjectsClient.publishContextObject(contextObject.value.id);
            contextObject.value.published = new Date();

        } finally {
            publishing.value = false;
        }

    }

    const unpublishContextObject = async () => {

        if (!contextObject.value) {
            return;
        }

        try {
            unpublishing.value = true;
            await contextObjectsClient.unpublishContextObject(contextObject.value.id);
            contextObject.value.published = undefined;
        } finally {
            unpublishing.value = false;
        }

    }

    const updateContextObject = async () => {

        saving.value = true;

        try {

            const command = new UpdateContextObjectCommand({
                name: workingCopy.value!.name,
                contextObjectId: workingCopy.value!.id,
                description: workingCopy.value!.description,
                contents: workingCopy.value!.contents as ContentDto[],
                title: workingCopy.value!.title,
                condition: workingCopy.value!.condition,
                languageIds: workingCopy.value?.languages.map((l) => l.id) ?? [],
                defaultLanguageId: workingCopy.value!.defaultLanguage.id,
                sharingOptions: workingCopy.value!.sharingOptions
            })

            contextObject.value = await contextObjectsClient.updateContextObject(contextObject.value!.id, command);

        } catch (e) {
            console.log(e);
        } finally {
            saving.value = false;
        }

    }

    const reset = () => {
        loading.value = false;
        saving.value = false;
        contextObject.value = undefined;
    }

    return {
        contextObject,
        workingCopy,
        loading,
        isPublished,
        publishing,
        unpublishing,
        saving,
        languages,
        defaultLanguage,
        reset,
        resetWorkingCopy,
        updateContextObject,
        getContextObject,
        publishContextObject,
        unpublishContextObject,
        getElement,
        updateElement
    }

})
