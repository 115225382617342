import type {RouteRecordRaw} from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: 'audit',
        name: 'audit.index',
        meta: {
            breadcrumb: {
                key: 'audit.entity',
                plural: 2
            }
        },
        component: () => import('@/modules/audit/views/AuditIndexView.vue')
    }
]


export default routes;
