import type {RouteRecordRaw} from "vue-router";
import {useScheduleStore} from "@/modules/schedules/stores/schedule";

const routes: RouteRecordRaw[] = [
    {
        path: 'schedules',
        children: [
            {
                path: '',
                name: 'schedules.index',
                component: () => import('@/modules/schedules/views/SchedulesIndexView.vue')
            },
            {
                path: ':scheduleId',
                name: 'schedules.show',
                beforeEnter: async (to) => {
                    const scheduleStore = useScheduleStore();
                    const {scheduleId} = to.params;
                    await scheduleStore.loadSchedule(scheduleId as string);

                    return !!scheduleStore.schedule;
                },
                component: () => import('@/modules/schedules/views/SchedulesShowView.vue')
            }
        ]
    }
];

export default routes;
