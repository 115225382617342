import {computed, ref} from "vue";
import {useTenantClient} from '@/utils/api.utils'
import {defineStore} from "pinia";
import type {TenantDetailsDto} from "@/api/TenantAPIClient";
import {UpdateTenantCommand} from "@/api/TenantAPIClient";
import type { TenantDto } from '@/api/GatewayAPIClient'
import {useCurrentUserStore} from "@/stores/current-user";

export const useCurrentTenantStore = defineStore('current-tenant', () => {

    const currentUserStore = useCurrentUserStore();
    const tenantClient = useTenantClient();
    const tenant = ref<TenantDetailsDto | null>(null);

    const meta = computed(() => {
        return currentUserStore.tenants.find(({id}) => tenant.value?.id === id);
    });

    const loadCurrentTenant = async () => {
        tenant.value = await tenantClient.getTenant();
    }

    const updateCurrentTenant = async (command: UpdateTenantCommand) => {
        tenant.value = await tenantClient.updateTenant(command);
    }

    return {
        tenant,
        meta,
        loadCurrentTenant,
        updateCurrentTenant
    };
})
