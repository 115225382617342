<script setup lang="ts">
import {useBreadcrumbs} from "@/composables/breadcrumbs";

const {breadcrumbs} = useBreadcrumbs();

</script>

<template>
  <v-breadcrumbs v-if="breadcrumbs.length" density="compact" class="app-breadcrumbs pa-0 h-100 w-100" :items="breadcrumbs" divider="/" />
</template>

<style lang="scss">
.app-breadcrumbs {
  font-size: .8rem;

  .v-breadcrumbs-item{
    padding: 0;
  }

  .v-breadcrumbs-divider {
    padding: 0 4px;
  }
}
</style>
