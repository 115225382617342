import {defineStore} from "pinia";
import {ref} from "vue";
import {ApiException, type ScheduleDetailsDto, StepAction, StepDto, UpdateScheduleCommand} from "@/api/TenantAPIClient";
import {useSchedulesClient} from "@/utils/api.utils";
import {getUniqueIdentifier} from "@/utils/identifier";
import {useSnackbarStore} from "@/stores/snackbar";

export const useScheduleStore = defineStore(`schedule`, () => {

    const client = useSchedulesClient();
    const loading = ref<boolean>(false)
    const schedule = ref<ScheduleDetailsDto>();

    const {error} = useSnackbarStore();

    const loadSchedule = async (scheduleId: string) => {

        try {
            loading.value = true;
            schedule.value = await client.getSchedule(scheduleId);
        } finally {
            loading.value = false;
        }

    }

    const addStep = () => {

        const step = new StepDto({
            identifier: getUniqueIdentifier(),
            name: '',
            action: StepAction.None,
        });

        schedule.value?.processModel.steps.push(step);

    }

    const updateSchedule = async () => {

        if (!schedule.value) return;


        const command = new UpdateScheduleCommand({
            scheduleId: schedule.value.id,
            name: schedule.value.name,
            steps: schedule.value.processModel.steps,
            transitions: schedule.value?.processModel.transitions,
        });

        try {
            await client.updateSchedule(schedule.value.id, command);
        } catch (e) {
            error((e as ApiException).message)
        }

    }

    return {
        schedule,
        loading,
        loadSchedule,
        updateSchedule,
        addStep
    }

})
